.a4-page {
    width: 790px;
    height: 1120px;
    padding-inline: 70px;
    padding-top: 70px;
    padding-bottom: 50px;
}

.content {
    width: 100%;
    height: 100%;
    font-size: smaller;
}

.addresses {
    margin-top: 30px;
}

.address {
    padding-bottom: 20px;
}

.logo {
    float: right;
    width: 100px;
    height: 100px;
    margin-bottom: 30px;
}

.comment {
    font: inherit;
    min-height: 60px;
}

.invoice-header {
    display: flex;
}

.debug.a4-page {
    outline: solid 1px black;
}

.debug>.content {
    background-color: white;
}

.align-right {
    text-align: right;
    padding-right: 0px;
}

.invoice-items>table>tbody {
    font-size: smaller;
}

.invoice-footer {
    position: relative;
}

.spacer-line {
    outline: solid 1px black;
    height: 0;
    width: 100%;
    margin-bottom: 10px;
}

.misc-infos {
    text-align: center;
}

.misc-infos>b {
    margin-left: 8px;
}

.grey-row {
    background-color: rgb(230, 230, 230);
}